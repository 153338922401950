import request from '@/utils/request'

export default {
  // 创建会员充值的订单
  createVipOrder(id,token,key,time,md5){
    return request({
      url: '/vip/create/order',
      method: 'post',
      headers: {
        token: token,
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: {
        vipId: id
      }
    })
  },
  // 获取会员商品信息
  getVipList(token){
    return request({
      url: '/vip/list',
      method: 'get',
      headers: {
        token: token
      }
    })
  }
}
