<template>
  <UserTop :title="title" @userInfo="getUserInfo">
    <div class="right-box">

      <div class="member-box">
        <div class="member-title">
          <img src="@/assets/images/icon/member2.png" alt="">
          <div class="txt">成为会员即可享受<span>尊享6项权益</span></div>
        </div>
        <div class="member-service">
          <div class="service-item" v-for="item in serviceList">
            <img :src="item.icon" alt="">
            <span>{{item.title}}</span>
          </div>
        </div>
        <div class="member-card">

          <div class="pc-show" v-if="memberList && memberList.length > 0">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane :label="item.name" :name="item.name" v-for="item in memberList">
                <div class="card-box">
                  <div class="card-item" :class="'card-color'+index" v-for="(val,index) in item.goodsVos">
                    <div class="card-title">
                      <h2>{{val.topTag}}</h2>
                      <span>{{val.introduce}}</span>
                    </div>
                    <div class="card-info">
                      <div class="org">
                        ￥
                        <span>{{val.price}}</span>
                        元/{{val.topTag}}
                      </div>
                      <div class="pre">
                        <span class="p">{{val.showPrice}}元</span>
                        <span class="z">{{ val.reduction }}</span>
                      </div>
                    </div>
                    <div class="card-btn" @click="openUp('show-pc',val)">立享优惠</div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>

          <div class="h5-show" v-if="memberList && memberList.length > 0">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane :label="item.name" :name="item.name" v-for="item in memberList">
                <el-carousel type="card" :interval="5000" height="360px">
                  <el-carousel-item class="card-item" :class="'card-color'+index" v-for="(val,index) in item.goodsVos" :key="val.topTag">
                    <div class="card-title">
                      <h2>{{val.topTag}}</h2>
                      <span>{{val.introduce}}</span>
                    </div>
                    <div class="card-info">
                      <div class="org">
                        ￥
                        <span>{{val.price}}</span>
                        元/{{val.topTag}}
                      </div>
                      <div class="pre">
                        <span class="p">{{val.showPrice}}元</span>
                        <span class="z">{{ val.reduction }}</span>
                      </div>
                    </div>
                    <div class="card-btn" @click="openUp('show-h5',val)">立享优惠</div>
                  </el-carousel-item>
                </el-carousel>
              </el-tab-pane>
            </el-tabs>
          </div>

        </div>
      </div>

      <DownloadPC ref="DownloadPC" @downloadOff="downloadOff" />
      <DownloadH5 ref="DownloadH5" @downloadOff="downloadOff" />

    </div>
  </UserTop>
</template>
<script>
import VipApi from '@/api/vip'
import CommonApi from "@/api/common"
import UserTop from "@/views/user_promot/components/userTop"
import DownloadH5 from "@/components/downloadH5/downloadH5.vue"
import DownloadPC from "@/components/downloadPC/index.vue"
export default {
  name: 'member',
  components: {
    DownloadPC,
    DownloadH5,
    UserTop
  },
  data() {
    return {
      title: '成为会员',
      userInfo: {
        isLogin: false, // 用户登录状态
        username: '',
        nickname: '',
        token: ''
      },
      memberList: [],
      serviceList: [{
        icon: require('@/assets/images/member/icon01.png'),
        title: '专属客服'
      },{
        icon: require('@/assets/images/member/icon02.png'),
        title: '定制化服务'
      },{
        icon: require('@/assets/images/member/icon03.png'),
        title: '内容建议'
      },{
        icon: require('@/assets/images/member/icon04.png'),
        title: '期刊文献'
      },{
        icon: require('@/assets/images/member/icon05.png'),
        title: '资源下载'
      },{
        icon: require('@/assets/images/member/icon06.png'),
        title: '高级功能解锁'
      }],
      activeName: ''
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getVipList()
  },
  beforeDestroy() {
    //清除定时器查询
    clearInterval(this.timer)
  },
  methods: {
    getUserInfo(msg){
      if (msg && msg.user === false){
        this.userInfo = this.$options.data().userInfo
        localStorage.removeItem('userInfo')
      }else {
        this.userInfo = msg.info
      }
    },
    openUp(type,val){
      let info = {
        title: val.title + '-' + val.topTag,
        id: val.vipId
      }
      this.createVipOrder(info,type)
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 获取会员商品
    getVipList(){
      VipApi.getVipList().then(res => {
        this.memberList = res.data
        this.activeName = this.memberList[0].name
      })
    },
    // 获取会员商品
    createVipOrder(info,type){
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      VipApi.createVipOrder(info.id,_this.userInfo.token,key,time,infoMd5).then(res => {
        let data = res.data
        let params = {
          show: true,
          title: info.title,
          info: data
        }
        if(type === 'show-pc'){
          _this.$refs.DownloadPC.showDrawer(params)
        }else if(type === 'show-h5'){
          _this.$refs.DownloadH5.showDrawer(params)
        }
        _this.getTimeFun(data.id)
      })
    },
    // 组件回调状态
    downloadOff(info){
      if(!info.payState){
        clearInterval(this.timer)
      }
    },
    // 查询订单支付状态
    getQueryPayState(orderId){
      CommonApi.queryPayState(orderId).then(res => {
        if(res.data){
          this.$message.success('支付成功')
          //清除定时器查询
          clearInterval(this.timer)
          // 关闭弹窗
          this.$refs.DownloadPC.handleClose()
        }
      }).catch(() => {
      })
    },
    //定时器查询订单状态
    getTimeFun(id){
      this.timer = setInterval(() => {
        this.getQueryPayState(id)
      }, 3000);
    }
  }
}
</script>
<style scoped lang="less">
.right-box{
  width: calc(100% - 200px);
  .member-box{
    border-radius: 15px;
    border: 2px solid #ffffff;
    background-color: rgba(255,255,255,0.3);
    .member-title{
      padding: 20px 0 0 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      img{
        width: 18px;
        height: 18px;
      }
      .txt{
        margin-left: 5px;
        font-size: 14px;
        font-weight: bold;
        span{
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid #d4d4d4;
          font-size: 12px;
          color: #6e83f7;
          font-weight: initial;
        }
      }
    }
    .member-service{
      width: 90%;
      margin: 0 auto;
      padding: 20px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .service-item{
        width: 16.6%;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          width: 56px;
          height: 56px;
        }
        span{
          height: 24px;
          line-height: 24px;
          color: #3c3c3c;
        }
      }
    }
    .member-card{
      width: 94%;
      margin: 0 auto;
      .pc-show{
        width: 100%;
        /deep/ .el-tabs__header {
          padding: 10px 20px;
          .el-tabs__nav-wrap::after{
            background-color: transparent !important;
          }
        }
        .card-box{
          padding: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }
      .h5-show{display: none}
      .card-item{
        width: 23%;
        border-radius: 15px;
        background-color: #ffffff;
        box-shadow: 0 0 5px rgba(0, 140, 250, 0.15);
        .card-title{
          padding: 15px 0;
          background-position: center top;
          background-repeat: no-repeat;
          background-size: 100% auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          h2{
            height: 40px;
            line-height: 40px;
            font-size: 22px;
          }
          span{
            height: 20px;
            line-height: 20px;
          }
        }
        .card-info{
          padding: 20% 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .org{
            font-size: 12px;
            color: #6c6c6c;
            span{
              font-size: 36px;
              //color: #ee6360;
              font-weight: bold;
              color: #3c3c3c;
            }
          }
          .pre{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            span.p{
              font-size: 12px;
              color: #9c9c9c;
              text-decoration: line-through;
            }
            span.z{
              position: relative;
              width: 40px;
              height: 18px;
              line-height: 18px;
              margin-left: 14px;
              text-align: center;
              color: #ffffff;
              font-size: 12px;
              border-radius: 5px;
              background-image: linear-gradient(90deg,#fa784a,#fc2c3f);
            }
            span.z::after{
              content: '';
              position: absolute;
              top: 2px;
              left: -4px;
              width: 14px;
              height: 14px;
              border-radius: 5px;
              background-image: linear-gradient(90deg,#fa784a,#fa6647);
              transform: rotate(45deg)
            }
          }
        }
        .card-btn{
          margin: 20px;
          padding: 10px 0;
          text-align: center;
          border-radius: 5px;
          cursor: pointer;
        }
        .card-btn:hover{
          color: #ffffff;
        }
      }
      .card-color0{
        .card-title{
          background-image: url('../../../assets/images/member/card01.png');
          h2{color: #068860;}
          span{color: #068860;}
        }
        .card-btn{
          color: rgb(81,195,159);
          border: 2px solid rgb(81,195,159);
        }
        .card-btn:hover{background-color: rgb(81,195,159);}
      }
      .card-color1{
        .card-title{
          background-image: url('../../../assets/images/member/card02.png');
          h2{color: rgb(194,130,30);}
          span{color: rgb(194,130,30);}
        }
        .card-btn{
          color: rgb(230,173,86);
          border: 2px solid rgb(230,173,86);
        }
        .card-btn:hover{background-color: rgb(230,173,86);}
      }
      .card-color2{
        .card-title{
          background-image: url('../../../assets/images/member/card03.png');
          h2{color: rgb(45,108,130);}
          span{color: rgb(45,108,130);}
        }
        .card-btn{
          color: rgb(142,205,232);
          border: 2px solid rgb(142,205,232);
        }
        .card-btn:hover{background-color: rgb(142,205,232);}
      }
      .card-color3{
        .card-title{
          background-image: url('../../../assets/images/member/card04.png');
          h2{color: rgb(37,89,192);}
          span{color: rgb(37,89,192);}
        }
        .card-btn{
          color: rgb(92,158,255);
          border: 2px solid rgb(92,158,255);
        }
        .card-btn:hover{background-color: rgb(92,158,255);}
      }
    }
  }
}

@media screen and (max-width: 900px){
  .right-box {
    width: 100%;
    .member-box{
      .member-service{
        flex-wrap: wrap;
        .service-item{
          width: 33%;
          padding: 20px 0 0 0;
          img{
            width: 30px;
            height: 30px;
          }
          span{
            font-size: 12px;
          }
        }
      }
      .member-card {
        .pc-show {
          display: none;
        }
        .h5-show {
          display: block;
          width: 150%;
          margin-top: -70px;
          margin-left: -25%;
          transform: scale(0.7);
          /deep/ .el-tabs__header{
            padding: 10px 20px !important;
            .el-tabs__item{
              font-size: 18px !important;
            }
          }
        }
        .card-item{
          width: 270px;
        }
      }
    }
  }
}
</style>
