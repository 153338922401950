<template>
  <div>
    <el-dialog :title="title"
               :visible.sync="dialogDrawer"
               :modal-append-to-body="false"
               width="43%"
               center
               :before-close="handleClose"
               :close-on-click-modal="false"
               :close-on-press-escape="false">
      <div class="pay-box">
        <div class="pay-title">
          <img src="@/assets/images/icon/order.png" alt=""/>
          <h3>支付订单</h3>
        </div>
        <div class="pay-cont">
          <div class="pay-img">
            <div v-if="orderDetail.qrCode">
              <generateQRCode :url="orderDetail.qrCode"></generateQRCode>
            </div>
          </div>
          <div class="pay-text">
            <p>总金额：<span>{{ orderDetail.price }}元</span><i>{{ orderDetail.showPrice }}元</i></p>
            <div class="pay-tip">
              <img src="@/assets/images/icon/wx-pay2.png" alt="">
              <img src="@/assets/images/icon/zfb-pay2.png" alt="">
              <p>使用微信、支付宝扫码支付<span>(扫码支付,请勿刷新页面)</span></p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import generateQRCode from "@/components/generateQRCode/generateQRCode.vue"
export default {
  components: {generateQRCode},
  data() {
    return {
      dialogDrawer: false,
      orderDetail: '',
      title: ''
    }
  },
  created() {
  },
  methods: {
    showDrawer(param) {
      this.dialogDrawer = param.show
      this.title = param.title
      this.orderDetail = param.info
    },
    handleClose() {
      let info = {
        showState: false,
        payState: false
      }
      this.$emit("downloadOff", info)
      this.dialogDrawer = false
    }
  }
}
</script>
<style scoped lang="less">
/deep/ .el-dialog{
  border-radius: 15px;
  .el-dialog__close {
    font-size: 20px;
  }
}
.pay-box{
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .pay-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    img{
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
    h3{
      font-size: 18px;
      font-weight: bold;
    }
  }
  .pay-cont{
    width: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .pay-img{
      position: relative;
      border-radius: 15px;
      border: none;
      width: 145px;
      height: 145px;
      >div{
        padding: 10px;
        border: 1px solid #e6e6e6;
        img{
          width: 124px;
          height: 124px;
          padding: 0;
          margin: 0;
          border: 0;
          background-color: #ffffff;
        }
      }
      >div:before,>div:after{
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
      }
      >div:before{
        bottom: 0;
        left: 0;
        border-bottom: 2px solid #36b7e6;
        border-left: 2px solid #36b7e6;
      }
      >div:after{
        bottom: 0;
        right: 0;
        border-bottom: 2px solid #36b7e6;
        border-right: 2px solid #36b7e6;
      }
    }
    .pay-img:before,
    .pay-img:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
    }
    .pay-img:before {
      top: 0;
      left: 0;
      border-top: 2px solid #36b7e6;
      border-left: 2px solid #36b7e6;
    }
    .pay-img:after {
      top: 0;
      right: 0;
      border-top: 2px solid #36b7e6;
      border-right: 2px solid #36b7e6;
    }
    .pay-text{
      padding-left: 30px;
      p{
        line-height: 30px;
        span{
          margin: 0 10px 0 5px;
          font-size: 24px;
          font-weight: bold;
          color: #fc5151;
        }
        i{
          font-size: 14px;
          text-decoration: line-through;
        }
      }
      .pay-tip{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        img{
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
        p{
          span{
            font-size: 14px;
            color: #409EFF;
          }
        }
      }
    }
  }
}
</style>
